








































































































import Vue from "vue";
import moment from "moment";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      items: [],
      totalItem: null,
      users: [],
      currentUser: null,
      currentDate: "",
      loading: false,
      headers: [
        { text: this.$t("PACKAGE_ID"), value: "package_id" },
        { text: this.$t("PACKAGE_BATCH_ID"), value: "package_batch_id" },
        { text: this.$t("TRUCK_ID"), value: "truck_id" },
        { text: "Hỗ trợ nhân viên", value: "sale_name" },
        { text: "Khách hàng", value: "customer_name" },
        { text: "Tiền vận chuyển", value: "fee_transport" },
        { text: "Lương DS", value: "salary_ds" },
        { text: "Ngày thanh lý", value: "liquidation_time" },
      ],
      timeModes: [
        {
          value: "month",
          text: "Tháng",
        },
        {
          value: "year",
          text: "Năm",
        },
      ],
      timeMode: "month",
    };
  },
  watch: {
    currentUser() {
      this.initialize();
    },
    currentDate() {
      this.initialize();
    },
    timeMode() {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      if (!this.currentUser || !this.currentDate) {
        return;
      }
      const m = moment(this.currentDate);
      this.loading = true;
      const options: any = {
        extra: {
          statistics: true,
          liquidation_slip_approve_time: {
            from: m.startOf(this.timeMode).unix(),
            to: m.endOf(this.timeMode).unix(),
          },
        },
        report: true,
      };
      if (this.currentUser.id) {
        options.auth_override = {
          id: this.currentUser.id,
          role: "htkd",
          codes: this.currentUser.codes || [],
          ids: [],
        };
      }
      let { items } = await apiClient.packageGetAll(options);
      items = items.map((item) => {
        item.salary_ds = item.fee_transport * 0.007;
        return item;
      });

      this.totalItem = items.reduce(
        (totalItem, item) => {
          totalItem.fee_transport += item.fee_transport;
          totalItem.salary_ds += item.salary_ds;
          return totalItem;
        },
        {
          fee_transport: 0,
          salary_ds: 0,
        }
      );

      this.items = items;
      this.loading = false;
    },
  },
  async created() {
    this.currentDate = moment().format("YYYY-MM");
    if (userManager.checkRole(["htkd"], true)) {
      const userInfo = userManager.getUserInfo();
      const user = {
        id: userInfo.sub,
        name: userInfo.name,
      };
      this.currentUser = user;
      this.users.push(user);
    } else {
      const { items } = await apiClient.userGetAll({
        filters: [
          {
            key: "roles",
            operator: "equal_to",
            value: "htkd",
          },
        ],
      });
      items.unshift({
        name: "ALL",
      });
      for (const user of items) {
        this.users.push(user);
      }
    }
  },
});
